import React, {  useState, useEffect } from 'react';
import axios from 'axios';
//import './listplanos.scss';
import { Button } from 'embravor-react/button';
import {  useAuth } from '../../contexts/auth';
import {  useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
} from 'embravor-react/form';

export interface IItens {
  id: string;
  id_tbaoti_0002:string;
  ordem:number;
  descricao:string;
}
export interface IPgto {
  qrcode:string;
  qrcode64:string;
  link_cielo?:string;
}
export interface IPlano {
  id: string;
  ordem: number;
  descricao: string;
  valor_avista: number;
  valor_prazo: number;
  id_sistema: string;
  inc_treinamento: boolean;
  inc_suporte: boolean;
  dias: number;
  meses: number;
  anos: number;
  pgto_pix: boolean;
  pgto_cielo: boolean;
  nro_cics: number;
  nro_users: number;
  e_commercer: boolean;
  compra_unica: boolean;
  licenca: boolean;
  id_old: number;
  descritivo: string;
  resumo: string;
  link_cielo: string;
  parc_sem_juros: number;
  titulo: string;
  valor_old: number;
  valor_anual: number;
  desc_pct: number;
  itens: IItens[];
}



export default function ListPlanos({ ...rest }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [planos, setPlanos] = useState<IPlano[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nivel, setNivel] = useState(0);
  const [pgto, setPgto] = useState<IPgto>({qrcode:'', qrcode64:'', link_cielo:''})

  useEffect(() => {
    axios.get(process.env.REACT_APP_API+'/planos')
      .then(response => {
        setPlanos(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  function Comprar (id: string) {
    
    if (user) {
      setLoading(true); 
      axios.post(process.env.REACT_APP_API+'/addcompra',{
        "id_tb0002":id
       }, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      })
      .then(response => {
        const { qrcode, qrcode64, link_cielo } = response.data;
        setNivel(1);
        setPgto({qrcode, qrcode64, link_cielo});
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
    } else {
      navigate('/login?returnUrl=/comprar');
    }
  }

  function ItemPlano({ item }: { item: IItens }){
    return <li
    style={{
      color: "rgb(54, 54, 54)",
      display: "flex",
      marginBottom: "16px",
    }}>
    <div
      style={{
        alignItems: "baseline",
        display: "flex",
        gap: "8px",
      }}>
      <svg
        fill="none"
        height="10"
        style={{
          display: "inline",
          height: "10px",
          width: "13.9844px",
        }}
        viewBox="0 0 14 10"
        xmlns="http://www.w3.org/2000/svg">
        <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(255, 164, 100)" fillRule="evenodd" />
      </svg>
      <div>
        {item.descricao}
      </div>
    </div>
  </li>
  }
  
  function PlanoNormal({ plano }: { plano: IPlano }) {
    //style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    return <div
    id="div-7"
    style={{
      
      float: "left",
      height: "100%",
      maxWidth: "342px",
      minHeight: "1px",
      paddingLeft: "16px",
      width: "266px",
    }}>
    <div
      style={{
        minWidth: "266px",
        width: "100%",
      }}>
      <div
        style={{
          backgroundColor: "rgb(255, 255, 255)",
          borderColor: "rgb(205, 216, 223)",
          borderRadius: "10px",
          borderStyle: "solid",
          borderWidth: "2px",
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          maxWidth: "100%",
          paddingBottom: "32px",
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "56px",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
          }}>
          <div
            style={{
              minHeight: "65px",
            }}>
            <h3
              id="h3-1"
              style={{
                color: "rgb(31, 32, 68)",
                fontSize: "24px",
                fontWeight: "700",
                marginBottom: "15px",
                minHeight: "60px",
                paddingRight: "40px",
              }}>
              {plano.titulo}
            </h3>
            <p
              id="p-1"
              style={{
                color: "rgb(31, 32, 68)",
                minHeight: "80px",
              }}>
              O{" "}
              <strong
                style={{
                  fontWeight: "700",
                }}>
                primeiro passo
              </strong>{" "}
              para iniciar sua jornada digital
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
          }}>
          <div
            id="div-8"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              minHeight: "101px",
              width: "100%",
            }}>
            <div
              style={{
                marginBottom: "16px",
              }}>
              <span
                style={{
                  color: "rgb(54, 54, 54)",
                  display: "inline-block",
                  fontSize: "14px",
                  marginRight: "10px",
                  textDecorationLine: "line-through",
                  verticalAlign: "bottom",
                }}>
                R$ {plano.valor_old}
              </span>
              <span
                style={{
                  backgroundColor: "rgb(186, 230, 211)",
                  borderRadius: "12px",
                  color: "rgb(31, 32, 68)",
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: "700",
                  marginBottom: "1px",
                  paddingBottom: "2px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "2px",
                  textAlign: "center",
                  textTransform: "uppercase",
                  verticalAlign: "middle",
                }}>
                {plano.desc_pct}% OFF
              </span>
            </div>
            <p
              style={{
                color: "rgb(0, 112, 209)",
                fontSize: "46px",
                fontWeight: "700",
              }}>
              <span
                style={{
                  fontSize: "14px",
                }}>
                R$
              </span>
              {plano.valor_avista}
              <span
                style={{
                  fontSize: "14px",
                }}>
                (à vista)
              </span>
            </p>
            <p
              style={{
                color: "rgb(0, 112, 209)",
                fontSize: "46px",
                fontWeight: "700",
              }}>
              <span
                style={{
                  fontSize: "14px",
                }}>
                R$
              </span>
              {plano.valor_prazo}
              <span
                style={{
                  fontSize: "14px",
                }}>
                (a prazo)
              </span>
            </p>
            <p
              style={{
                color: "rgb(54, 54, 54)",
                fontSize: "16px",
              }}>
              <strong
                style={{
                  fontWeight: "700",
                }}>
                R$ {plano.valor_anual}
              </strong>{" "}
              por 1 anos
            </p>
          </div>
            <Button  text="AOTI" onClick={() => Comprar(plano.id)}   type="success"           stylingMode="outlined">Comprar</Button>
         
          <ul
            style={{
              listStyleType: "none",
            }}>
            <li
              style={{
                color: "rgb(54, 54, 54)",
                display: "flex",
                fontWeight: "700",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                }}>
                <strong> Recursos do Plano </strong>
              </div>
            </li>
            
            {plano?.itens.map(it => ( 
                 <ItemPlano key={it.id} item={it} />            
                ))}
          </ul>
         
        </div>
      </div>
    </div>
  </div>
    ;
  }
  function PlanoRecomendado({ plano }: { plano: IPlano }) {
    return <div
    id="div-13"
    style={{
      float: "left",
      height: "100%",
      maxWidth: "342px",
      minHeight: "1px",
      paddingLeft: "16px",
      paddingRight: "16px",
      width: "100%",
    }}>
    <div
      style={{
        minWidth: "266px",
        width: "100%",
      }}>
      <div
        style={{
          backgroundColor: "rgb(255, 255, 255)",
          borderColor: "rgb(0, 112, 209)",
          borderRadius: "10px",
          borderStyle: "solid",
          borderWidth: "2px",
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          maxWidth: "100%",
          paddingBottom: "32px",
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "56px",
          position: "relative",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
          }}>
          <div
            style={{
              alignItems: "center",
              backgroundColor: "rgb(0, 112, 209)",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              color: "rgb(252, 252, 252)",
              display: "flex",
              fontWeight: "700",
              height: "40px",
              justifyContent: "center",
              marginLeft: "-24px",
              position: "absolute",
              top: "0px",
              width: "100%",
            }}>
            Recomendado
          </div>
          <div
            style={{
              minHeight: "65px",
            }}>
            <h3
              id="h3-4"
              style={{
                color: "rgb(31, 32, 68)",
                fontSize: "24px",
                fontWeight: "700",
                marginBottom: "15px",
                minHeight: "60px",
                paddingRight: "40px",
              }}>
              Plano Turbo
            </h3>
            <p
              id="p-4"
              style={{
                color: "rgb(31, 32, 68)",
                minHeight: "80px",
              }}>
              <strong
                style={{
                  fontWeight: "700",
                }}>
                Mais performance{" "}
              </strong>{" "}
              para sites com alto tráfego
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
          }}>
          <div
            id="div-14"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              minHeight: "101px",
              width: "100%",
            }}>
            <div
              style={{
                marginBottom: "16px",
              }}>
              <span
                style={{
                  color: "rgb(54, 54, 54)",
                  display: "inline-block",
                  fontSize: "14px",
                  marginRight: "10px",
                  textDecorationLine: "line-through",
                  verticalAlign: "bottom",
                }}>
                R$ 2.050,60
              </span>
              <span
                style={{
                  backgroundColor: "rgb(186, 230, 211)",
                  borderRadius: "12px",
                  color: "rgb(31, 32, 68)",
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: "700",
                  marginBottom: "1px",
                  paddingBottom: "2px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "2px",
                  textAlign: "center",
                  textTransform: "uppercase",
                  verticalAlign: "middle",
                }}>
                55% OFF
              </span>
            </div>
            <p
              style={{
                color: "rgb(0, 112, 209)",
                fontSize: "46px",
                fontWeight: "700",
              }}>
              <span
                style={{
                  fontSize: "14px",
                }}>
                R$
              </span>
              25,69
              <span
                style={{
                  fontSize: "14px",
                }}>
                /mês*
              </span>
            </p>
            <p
              style={{
                color: "rgb(54, 54, 54)",
                fontSize: "16px",
              }}>
              <strong
                style={{
                  fontWeight: "700",
                }}>
                R$ 922,77
              </strong>{" "}
              por 3 anos
            </p>
            <div
              style={{
                fontSize: "12px",
                marginTop: "16px",
              }}>
              Economize até{" "}
              <span
                style={{
                  color: "rgb(0, 112, 209)",
                  fontWeight: "700",
                }}>
                R$ 1.190,82
              </span>
              <p>contratando por 3 anos</p>
            </div>
          </div>
          <a
            href="https://www.hostgator.com.br/hospedagem-de-sites-seu-sucesso-passo-2?pid=335"
            id="a-4"
            style={{
              backgroundColor: "rgb(255, 207, 0)",
              color: "rgb(31, 32, 68)",
              fontSize: "20px",
              fontWeight: "700",
              marginBottom: "30px",
              marginTop: "30px",
              paddingBottom: "22px",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "17px",
              textAlign: "center",
              width: "100%",
            }}>
            Começar agora
          </a>
          <ul
            style={{
              color: "rgb(54, 54, 54)",
              listStyleType: "none",
            }}>
            <li
              style={{
                display: "flex",
                fontWeight: "700",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                }}>
                <strong>Recursos do Plano</strong>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  Hospede{" "}
                  <strong
                    style={{
                      fontWeight: "700",
                    }}>
                    sites e domínios ilimitados
                  </strong>
                </div>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  Até{" "}
                  <strong
                    style={{
                      fontWeight: "700",
                    }}>
                    10x
                  </strong>{" "}
                  mais performance
                </div>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  <strong
                    style={{
                      fontWeight: "700",
                    }}>
                    150 GB
                  </strong>{" "}
                  de armazenamento SSD
                </div>
              </div>
            </li>
            <li
              style={{
                cursor: "pointer",
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  1 ano de{" "}
                  <span
                    style={{
                      color: "rgb(0, 112, 209)",
                    }}>
                    domínio grátis
                  </span>
                </div>
              </div>
            </li>
            <li
              style={{
                cursor: "pointer",
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  Contas de{" "}
                  <span
                    style={{
                      color: "rgb(0, 112, 209)",
                    }}>
                    e-mail ilimitadas
                  </span>
                </div>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  Certificado SSL{" "}
                  <strong
                    style={{
                      fontWeight: "700",
                    }}>
                    Grátis
                  </strong>
                </div>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                Criação facilitada de sites, blogs e lojas
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                Monitoramento de spam e malwares com Sitelock
              </div>
            </li>
            <li
              style={{
                cursor: "pointer",
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  Até R$ 1.200,00 em{" "}
                  <span
                    style={{
                      color: "rgb(0, 112, 209)",
                    }}>
                    {" "}
                    créditos no Google Ads
                  </span>
                </div>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  <strong
                    style={{
                      fontWeight: "700",
                    }}>
                    2,5x
                  </strong>{" "}
                  mais visitas
                </div>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                Recomendado para projetos com alto tráfego e lojas
              </div>
            </li>
            <li
              style={{
                display: "flex",
                fontWeight: "700",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                }}>
                <strong>
                  Recursos otimizados para <br />
                  WordPress
                </strong>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  Instalação da última versão do{" "}
                  <strong
                    style={{
                      fontWeight: "700",
                    }}>
                    WordPress
                  </strong>{" "}
                  em poucos cliques
                </div>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  <strong
                    style={{
                      fontWeight: "700",
                    }}>
                    Onboarding guiado
                  </strong>{" "}
                  com sugestões baseadas em IA
                </div>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                Temas WP totalmente personalizados
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  Gerador de conteúdo baseado em{" "}
                  <strong
                    style={{
                      fontWeight: "700",
                    }}>
                    IA
                  </strong>
                </div>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  Plugins{" "}
                  <strong
                    style={{
                      fontWeight: "700",
                    }}>
                    SEO by Yoast{" "}
                  </strong>
                  grátis instalado
                </div>
              </div>
            </li>
            <li
              style={{
                cursor: "pointer",
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  <span
                    style={{
                      color: "rgb(0, 112, 209)",
                    }}>
                    Integrações de marketing
                  </span>{" "}
                  via plugins pré-instalados
                </div>
              </div>
            </li>
            <li
              style={{
                cursor: "pointer",
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                  gap: "8px",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                <div>
                  Suporte à{" "}
                  <strong
                    style={{
                      fontWeight: "700",
                    }}>
                    loja online
                  </strong>{" "}
                  via WooCommercee com{" "}
                  <span
                    style={{
                      color: "rgb(0, 112, 209)",
                    }}>
                    integrações exclusivas no painel
                  </span>
                </div>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                marginBottom: "16px",
              }}>
              <div
                style={{
                  alignItems: "baseline",
                  display: "flex",
                }}>
                <svg
                  fill="none"
                  height="10"
                  style={{
                    display: "inline",
                    height: "10px",
                    width: "13.9844px",
                  }}
                  viewBox="0 0 14 10"
                  xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M12.8006 0.366117C13.2887 0.854272 13.2887 1.64573 12.8006 2.13388L5.46722 9.46722C4.97906 9.95537 4.18761 9.95537 3.69945 9.46722L0.366117 6.13388C-0.122039 5.64573 -0.122039 4.85427 0.366117 4.36612C0.854272 3.87796 1.64573 3.87796 2.13388 4.36612L4.58333 6.81557L11.0328 0.366117C11.5209 -0.122039 12.3124 -0.122039 12.8006 0.366117Z" fill="rgb(48, 180, 122)" fillRule="evenodd" />
                </svg>
                Ofertas customizadas para checkout de lojas virtuais
              </div>
            </li>
          </ul>
          <div
            style={{
              color: "rgb(0, 112, 209)",
              display: "flex",
              fontWeight: "700",
              justifyContent: "center",
            }}>
            <a
              href="https://www.hostgator.com.br/#sharedhosting-plans"
              style={{
                textAlign: "center",
              }}>
              Ver mais recursos
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
    ;
  }

  function ListaPlanos() {
    return <section className='ListPlanos'
    id="section-1"
    style={{
      paddingBottom: "60px",
      width: "100%",
    }}>
    <div
      id="div-1"
      style={{
        backgroundColor: "rgb(246, 246, 249)",
        paddingBottom: "60px",
        paddingTop: "60px",
      }}>
      <h2
        id="h2-1"
        style={{
          color: "rgb(31, 32, 68)",
          fontSize: "46px",
          fontWeight: "700",
          marginBottom: "10px",
          marginLeft: "42px",
          marginRight: "42px",
          maxWidth: "940px",
          textAlign: "center",
        }}>
        Escolha o plano ideal para você!
      </h2>
      
    </div>
    
    <div
      id="div-3"
      style={{
        alignItems: "center",        
        justifyContent: "space-between",
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "visible",
        paddingLeft: "15px",
        paddingRight: "16px",
        position: "relative",
      }}>
      <div
        id="div-4">
        <div
          style={{
            position: "relative",
          }}>
          <div
            id="div-5"
            style={{
              margin: "-15px",
              overflow: "visible",
              position: "relative",
            }}>
            <div
              id="div-6"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(266px, 1fr))",
                gap: "15px",
                margin: "auto",
                maxWidth: "1200px",
              }}>
              {planos.map(plano => ( 
                 <PlanoNormal key={plano.id} plano={plano}           
                 />
              
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </section>;
  }
  function Menu() {
    if (nivel === 0) {
      return <ListaPlanos />;
    }

    return (
      
        <div className="center"
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
     
          >
        
        <form className={'login-form'} >
          <Form formData={pgto} disabled={loading}
          
          >
            <Item>
            <h2 className={'content-block'}>Compra Concluida</h2> 
            </Item>

            <Item
              dataField={'qrcode'}
              editorType={'dxTextBox'}
              
            >
              <Label visible={true} />
            </Item>
          
            <Item >
            
              <div className={'form-avatar'} style={{ width: "300px", height: "300px" }}>                
                <img 
                  
                  alt={''}
                  src={`data:image/png;base64,`+pgto.qrcode64}
                  style={{ width: "300px", height: "300px" }}
                />
              </div>
            
            </Item>
              <Item>
                <h2 className={'content-block'}>Pagamento com Cartão</h2>
                <div className={'form-avatar'} style={{ width: "300px", height: "300px" }}>     
                  <a href={pgto.link_cielo} target='_blank'>
                    <img 
                      
                      alt={''}
                      src={'/img/cielo.jpg'}
                      style={{ width: "300px", height: "300px" }}
                    />
                  </a>           
                  
                </div>
            </Item>
            
          </Form>
        </form>
      </div>
    
      
    );
  }
  return <Menu/> 
  
  


;
}
