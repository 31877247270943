// SuporteForm.tsx
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule
} from 'embravor-react/form';
import LoadIndicator from 'embravor-react/load-indicator';
import notify from 'embravor/ui/notify';
import { useDados } from '../../contexts/dados';
import './LigamosForm.scss';
import { DuoCard } from '../../layouts';
import { SingleCard } from '../../layouts';

export default function LigamosForm() {
  const navigate = useNavigate();
  const {LigamosIn} = useDados();
  const [loading, setLoading] = useState(false);
  const [ok, setOk] = useState(false);
  const formData = useRef({ nome: '', telefone: '', email: '' });

  const onSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const result = await LigamosIn(formData.current);
    setLoading(false);

    if (result.isOk) {
     // navigate('/login');
      setOk(true);
    } else {
      notify(result.message, 'error', 2000);
    }
    
  }, [navigate]);

  

  const NomeEditorOptions = { stylingMode: 'filled', placeholder: 'Nome' };
  const TelefoneEditorOptions = { stylingMode: 'filled', placeholder: 'Celular', mask: '+55 (00) 00000-0000' };
  const EmailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };


   function Enviado() {
      return (
        <div> 
            <SingleCard title="Solicitação">
            <h1><b>Enviado</b> </h1> 
            </SingleCard>
            
                
        </div>
      );
   }
   function Formulario() {
     return (
       <div>
          <DuoCard title="Suporte">
            <form className="create-account-form" onSubmit={onSubmit}>
              <Form formData={formData.current} disabled={loading}>
                <Item dataField="nome" editorType="dxTextBox" editorOptions={NomeEditorOptions}>
                  <RequiredRule message="Nome é requerido" />
                  <Label visible={false} />
                </Item>
                <Item dataField="telefone" editorType="dxTextBox" editorOptions={TelefoneEditorOptions}>
                  <RequiredRule message="Telefone é requerido" />
                  <Label visible={false} />
                </Item>
                <Item dataField="email" editorType="dxTextBox" editorOptions={EmailEditorOptions}>
                  <RequiredRule message="Email é requerido" />
                  <EmailRule message="Email inválido" />
                  <Label visible={false} />
                </Item>
                <Item>
                  <div className="policy-info">
                    Por favor forneça informações verdadeiras para que nosso comercial possa lhe encontrar.
                  </div>
                </Item>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior={true}>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible={true} /> : 'Enviar Solicitação de Contato'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
              </Form>
            </form>
          </DuoCard>
       </div>
     );
   }


  return (
    <div>
      {ok ? (
          <Enviado/>
        ) : (
          <Formulario/>
        )}
    </div>
  );
}
