import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  GroupItem,
  EmailRule
} from 'embravor-react/form';
import notify from 'embravor/ui/notify';
import LoadIndicator from 'embravor-react/load-indicator';
import { createAccount } from '../../api/auth';
import { ValidationCallbackData } from 'embravor-react/common';
import './CreateAccountForm.scss';
import type {  Cad  } from '../../types';

export default function CreateAccountForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [ufs, setUfs] = useState([]); // [1
  const formData = useRef<Cad>({ 
    cpf_cnpj: '',
    nome_razaosocial: '',
    apelido_fantasia: '',
    end_log: '',
    end_num: '',
    end_compl: '',
    end_bairro: '',
    end_cidade: '',
    end_uf: '',
    end_cep: '',
    tel_num: '',
    email: '', 
    password: '' });

  const onSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    setLoading(true);

    

    const result = await createAccount(formData.current);
    setLoading(false);

    if (result.isOk) {
      navigate('/login');
    } else {
      notify(result.message, 'error', 2000);
    }
  }, [navigate]);

  const confirmPassword = useCallback(
    ({ value }: ValidationCallbackData) => value === formData.current.password,
    []
  );

  useEffect(() => {
    axios.get(process.env.REACT_APP_API+'/ufs')
      .then(response => {
        setUfs(response.data);
      })
      .catch(error => {
        setLoading(false);
      });
  }, []);

  const cpf_cnpjEditorOptions = { stylingMode: 'filled', placeholder: 'CPF/CNPJ', label: 'CPF/CNPJ'};
  const nome_razaosocialEditorOptions = { stylingMode: 'filled', placeholder: 'Nome/Razão' };
  const apelido_fantasiaEditorOptions = { stylingMode: 'filled', placeholder: 'Apelido/Fantasia' };
  const end_logEditorOptions = { stylingMode: 'filled', placeholder: 'Logradouro'};
  const end_numEditorOptions = { stylingMode: 'filled', placeholder: 'Número' };
  const end_complEditorOptions = { stylingMode: 'filled', placeholder: 'Complemento' };
  const end_bairroEditorOptions = { stylingMode: 'filled', placeholder: 'Bairro' };
  const end_cidadeEditorOptions = { stylingMode: 'filled', placeholder: 'Cidade' };
  const end_ufEditorOptions = { stylingMode: 'filled', placeholder: 'UF', valueExpr:"id", displayExpr:"label", dataSource: ufs};
  const end_cepEditorOptions = { stylingMode: 'filled', placeholder: 'CEP',  mask: '00000-000'  };
  const tel_numEditorOptions = { stylingMode: 'filled', placeholder: 'Celular',  mask: '+55 (00) 00000-0000'  };
  const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
  const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
  const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' };


  return (
    <form className={'create-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
         <Item
          dataField={'cpf_cnpj'}
          editorType={'dxTextBox'}
          title={'CPF/CNPJ'}
          caption={'CPF/CNPJ'}
          editorOptions={cpf_cnpjEditorOptions}
          >
          <RequiredRule message="CPF/CNPJ is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'nome_razaosocial'}
          editorType={'dxTextBox'}
          editorOptions={nome_razaosocialEditorOptions}
          >
          <RequiredRule message="Nome/Razão Social is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'apelido_fantasia'}
          editorType={'dxTextBox'}
          editorOptions={apelido_fantasiaEditorOptions}
          >
          <Label visible={false} />
        </Item>
        <Item
          dataField={'end_log'}
          editorType={'dxTextBox'}
          editorOptions={end_logEditorOptions}
          >
          <Label visible={false} />
        </Item>
        <GroupItem cssClass="form-group" colCount={2}>
        <Item
          dataField={'end_num'}
          editorType={'dxTextBox'}
          editorOptions={end_numEditorOptions}
          >
          <Label visible={false} />
        </Item>
        <Item
          dataField={'end_compl'}
          editorType={'dxTextBox'}
          editorOptions={end_complEditorOptions}
          >
          <Label visible={false} />
        </Item>
        </GroupItem>
        
        <GroupItem cssClass="form-group" colCount={2}>
        <Item
          dataField={'end_bairro'}
          editorType={'dxTextBox'}
          editorOptions={end_bairroEditorOptions}
          >
          <Label visible={false} />
        </Item>
        <Item
          dataField={'end_cidade'}
          editorType={'dxTextBox'}
          editorOptions={end_cidadeEditorOptions}
          >
          <Label visible={false} />
        </Item>

        </GroupItem>
        <GroupItem cssClass="form-group" colCount={2}>
        <Item
          dataField={'end_uf'}
          editorType={'dxSelectBox'}
          editorOptions={end_ufEditorOptions}
          >
          <RequiredRule message="UF is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'end_cep'}
          editorType={'dxTextBox'}
          editorOptions={end_cepEditorOptions}
          >
          <Label visible={false} />
        </Item>
        </GroupItem>
           
        
        <Item
          dataField={'tel_num'}
          editorType={'dxTextBox'}
          editorOptions={tel_numEditorOptions}
          >
          <RequiredRule message="Celular is required" />
          <Label visible={false} />
        </Item>
       
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message="Email is required" />
          <EmailRule message="Email is invalid" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <Item>
          <div className='policy-info'>
          Ao criar uma conta, você concorda com os <Link to="/termo">Termo de serviço</Link> and <Link to="/privacidade">Politica de Privacidade</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Criar Nova Conta'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            <Link to={'/login'}>Já tem uma conta? clique aqui</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}


