// SuporteForm.tsx
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule
} from 'embravor-react/form';
import LoadIndicator from 'embravor-react/load-indicator';
import { useDados } from '../../contexts/dados';
import './SuporteForm.scss';
import { DuoCard } from '../../layouts';

export default function SuporteForm() {
  const navigate = useNavigate();
  const {  horarioAtendimento,  HorarioAtendimentoIn} = useDados();
  const [loading, setLoading] = useState(false);
  const [ok, setOk] = useState(false);
  const formData = useRef({ nome: '', empresa: '', telefone: '', email: '' });

  const onSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setOk(true);
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await HorarioAtendimentoIn();
        if (result.isOk) {
          console.log('Dados buscados com sucesso:', result);
        }
      } catch (err) {
        console.error('Erro ao buscar dados:', err);
      }
    };
    fetchData();
  }, [HorarioAtendimentoIn]);

  const NomeEditorOptions = { stylingMode: 'filled', placeholder: 'Nome' };
  const EmpresaEditorOptions = { stylingMode: 'filled', placeholder: 'Empresa' };
  const TelefoneEditorOptions = { stylingMode: 'filled', placeholder: 'Celular', mask: '+55 (00) 00000-0000' };
  const EmailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };

   function Suporte() {
      return (
        <div>        
            <iframe title="Suporte"
              src={`https://secure.logmeinrescue.com/Customer/Download.aspx?EntryID=1228382032&name=${formData.current.nome}&comment1=${formData.current.empresa}&comment2=${formData.current.telefone}&comment3=${formData.current.email}`}
              style={{ height: "1000px", maxWidth: "100%", width: "100%" }}
            />       
        </div>
      );
   }
   function Formulario() {
     return (
       <div>
          <DuoCard title="Suporte">
            <form className="create-account-form" onSubmit={onSubmit}>
              <Form formData={formData.current} disabled={loading}>
                <Item dataField="nome" editorType="dxTextBox" editorOptions={NomeEditorOptions}>
                  <RequiredRule message="Nome é requerido" />
                  <Label visible={false} />
                </Item>
                <Item dataField="empresa" editorType="dxTextBox" editorOptions={EmpresaEditorOptions}>
                  <Label visible={false} />
                </Item>
                <Item dataField="telefone" editorType="dxTextBox" editorOptions={TelefoneEditorOptions}>
                  <RequiredRule message="Telefone é requerido" />
                  <Label visible={false} />
                </Item>
                <Item dataField="email" editorType="dxTextBox" editorOptions={EmailEditorOptions}>
                  <RequiredRule message="Email é requerido" />
                  <EmailRule message="Email inválido" />
                  <Label visible={false} />
                </Item>
                <Item>
                  <div className="policy-info">
                    Por favor forneça informações verdadeiras para que nosso suporte possa lhe identificar.
                  </div>
                </Item>
                <ButtonItem>
                  <ButtonOptions width="100%" type="default" useSubmitBehavior={true}>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width="24px" height="24px" visible={true} /> : 'Acessar o Suporte'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
              </Form>
            </form>
          </DuoCard>
       </div>
     );
   }
  function Comercial() {
    return (
      <div>
        {ok ? (
          <Suporte/>
        ) : (
          <Formulario/>
        )}
      </div>
    );
  }
  function NaoComercial() {
    return (
      <DuoCard title="Alerta">
        <div className="card-body">
          <div className="alert alert-warning alert-dismissible">
            <p><b>Caro usuário, horário de atendimento comercial encerrado. Volte mais tarde.</b></p>
            <br></br>
            <p><b>Horario de Manha:</b> {horarioAtendimento?.amanha_ini} às {horarioAtendimento?.amanha_fim}</p>
            <p><b>Horario de Tarde:</b> {horarioAtendimento?.tarde_ini} às {horarioAtendimento?.tarde_fim}</p>
            <p><b>Horario atual:</b> {horarioAtendimento?.hora_atual}</p>
            <p>Horario de Brasilia, de Segunda a Sexta</p>
            <p>Não Abrimos em Feriados</p>
          </div>
        </div>
      </DuoCard>
    );
  }
  function Bloqueado() {
    return (
      <DuoCard title="Alerta">
        <div className="card-body">
          <div className="alert alert-danger alert-dismissible">
            <p>{horarioAtendimento?.motivo}</p>
          </div>
        </div>
      </DuoCard>
    );
  }

  return (
    <div>
      {horarioAtendimento?.bloqueado && <Bloqueado />}
      {!horarioAtendimento?.bloqueado && horarioAtendimento?.hrcomercial ? <Comercial /> : null}
      {!horarioAtendimento?.bloqueado && !horarioAtendimento?.hrcomercial ? <NaoComercial />: null}
    </div>
  );
}
