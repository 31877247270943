
import * as React from 'react';
import Box from '@material-ui/core/Box';

export default function FloatingWhatsapp() {
  return (
    // sx={{
    //   position: 'fixed',
    //   bottom: '20px',
    //   right: '20px',
    //   m: 1 ,
    //  // '& > :not(style)': { m: 1 },
    // }}
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        
      }}
     
    >
      <a href='https://wa.me/5565981676400' target='_blank'>
        <img src="img/whatsapp.png"/>
      </a>
        
     
      
    </div>
  );
}