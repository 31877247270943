import React, { useCallback, useState,useEffect } from 'react';
import Menu, { Item } from 'embravor-react/menu';
import { Button } from 'embravor-react/button';
import DropDownButton, { DropDownButtonTypes } from 'embravor-react/drop-down-button';
import { useAuth } from '../../contexts/auth';
import { useDados } from '../../contexts/dados';
import {  useNavigate } from "react-router-dom";
import './header3.scss';
import { useScreenSizeMenuClass } from '../../utils/media-query';
import { TCountAcess } from '../../types';


const buttonDropDownOptions = { width: 230 };
function clickAoti() {
  window.open("https://embravor.com.br", "_blank");
}
function MenuOpen(route :string) {
  window.open(route, "_blank");
}

function clickWhatsapp() {
  window.open("https://wa.me/5565981676400", "_blank");
}


const SOU_CLIENTE = ['Login', 'Suporte', 'Meus Dados'];
const SOU_CLIENTE2 = ['/login', '/suporte', '/meusdados'];





export default function Header3({ ...rest }) {
  const navigate = useNavigate();
  const screenSizeMenuClass = useScreenSizeMenuClass();
  const { user, signOut, loading } = useAuth();
  const [acessCount, setAcessCount] = useState<TCountAcess>({qtde:0});
  const {CountAcessIn} = useDados();

  function clickSuporte() {
    navigate('/suporte');
  }
  function clickLigamosVoce() {
   
    navigate('/ligamos');
  
  }

  const onItemClick = useCallback((e: DropDownButtonTypes.ItemClickEvent) => {
      //  notify(e.itemData.name || e.itemData, 'success', 600);
      const index = SOU_CLIENTE.indexOf(e.itemData);
      const rota = SOU_CLIENTE2[index];

      navigate(rota);  
  }, []);
  
  useEffect(() => {
    (async function () {
      const result = await CountAcessIn();
      if (result.isOk) {
        setAcessCount(result.data);
      }
    })();
  }, []);

  
  return <div>
  <div id="container">
      <Menu 
      orientation={screenSizeMenuClass}
      >
          <Item  icon="home"  url="/#home" />
          {!user &&<Item  text="Cadastre-se" url="/#cadastrese"/>}                
          <Item  text="Comprar" url="/#comprar"/>
          <Item  text="Download" url="/#download"/>
          
          <Item  text="Novidades" url="/#novidades"/>
          
          <Item >
            <Button  text="SUPORTE" onClick={clickSuporte}   type="default"           stylingMode="contained"/>
          </Item>                
          <Item >
            <Button  text="WHATSAPP" onClick={clickWhatsapp}   type="success"           stylingMode="contained"/>
          </Item>
          <Item >
            <Button  text="LIGAMOS PARA VOCÊ" onClick={clickLigamosVoce} type="danger"           stylingMode="contained"/>
          </Item>
            {user && 
              <Item >
                <Button  text="Logoff" onClick={signOut} type="danger"           stylingMode="contained"/>
              </Item>
            }          
         

          <Item>
            <DropDownButton
              text="SOU CLIENTE"
              dropDownOptions={buttonDropDownOptions}
              items={SOU_CLIENTE}
              onItemClick={onItemClick}
            />
          </Item>
          <Item  text="Acessos: " >
            <div>Acessos ao Site: {acessCount.qtde}</div>

      
          </Item>
          
      </Menu>
  </div>
</div>;
}
